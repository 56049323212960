import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { CurrentUser, CustomerEventCounterData } from 'types';
import { isEmpty } from 'utils/toggleParams';
import { checkIfSignUpIncomplete, checkIsAdmin, goTo } from 'utils/services';
import { removeLogInDataFromBrowser } from 'utils/localStoreTools';
import { NextIcon } from 'components/NextImage';
import { MenuModal } from 'components/MenuModal';
import { AccountAvatar } from 'components/AccountAvatar';

import styles from './AccountMenu.module.scss';

export type AccountMenuProps = {
  user?: CurrentUser | null;
  eventCounterData?: CustomerEventCounterData;
  trackEventCounter?: () => void;
};

export const AccountMenu = ({ user, eventCounterData, trackEventCounter }: AccountMenuProps) => {
  const router = useRouter();

  const [active, setActive] = useState(false);
  const isLoggedIn = !!user?.email;
  const isSignUpIncomplete = checkIfSignUpIncomplete(user);

  const isAdmin = checkIsAdmin(user);
  const {
    unread_messages_count: unreadMessages,
    unread_sell_orders: newSellOrders,
    unread_purchase_orders: newPurchaseOrders,
    unread_offers: newOffers,
  } = eventCounterData || {};

  const toggleActive = () => {
    setActive((active) => !active);
  };

  const closeMenu = () => setActive(false);

  useEffect(() => {
    !isAdmin && user?.email && trackEventCounter && trackEventCounter();

    if (active) {
      setTimeout(() => {
        document.addEventListener('click', closeMenu, { once: true });
      }, 100);
    } else {
      document.removeEventListener('click', closeMenu);
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  const logOut = () => {
    removeLogInDataFromBrowser();
    router.reload();
  };

  const protectedRedirect = (router: any, path: string) => {
    if (!isLoggedIn) {
      goTo(router, `/sign-in?redirect=${path}`);
      return;
    }
    if (isSignUpIncomplete) {
      goTo(router, `/sign-up?redirect=${path}`);
      return;
    }
    goTo(router, path);
  };

  const addAdminPanelLinks = () => {
    const links = [];
    if ((!!user?.permissions && user.permissions.includes('dashboard_view')) || user?.role === 'super_admin') {
      links.push({
        alt: 'Dashboard',
        iconSrc: '/icons/design/SquaresFourDark.svg',
        onClick: () => goTo(router, '/admin-panel/dashboard'),
        title: 'Dashboard',
      });
    }

    return links;
  };

  const adminMenuItems = [
    ...addAdminPanelLinks(),
    {
      alt: 'icon',
      iconSrc: '/icons/system/GearSixDark.svg',
      onClick: () => goTo(router, '/account-settings'),
      title: 'Settings',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/system/SignOutDark.svg',
      onClick: logOut,
      title: 'Log out',
    },
  ];

  const userMenuItems = [
    {
      alt: 'icon',
      iconSrc: '/icons/maps/SquaresFour.svg',
      onClick: () => protectedRedirect(router, '/feed'),
      title: 'Feed',
      hideOnDesktop: true,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/commerce/ShoppingBagDark.svg',
      onClick: () => protectedRedirect(router, '/my-orders/sell'),
      title: 'Sales',
      hideOnDesktop: true,
      notification: newSellOrders,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/commerce/ShoppingCartDark.svg',
      onClick: () => protectedRedirect(router, '/my-orders/purchase'),
      title: 'Purchases',
      hideOnDesktop: true,
      notification: newPurchaseOrders,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/commerce/TagDark.svg',
      onClick: () => protectedRedirect(router, '/my-offers'),
      title: 'Offers',
      notification: newOffers,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/communications/ChatCircleDark.svg',
      onClick: () => protectedRedirect(router, '/messages'),
      title: 'Messages',
      hideOnDesktop: true,
      notification: unreadMessages,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/design/PlusCircleDark.svg',
      onClick: () => protectedRedirect(router, '/item-management'),
      title: 'Create Gear',
      hideOnDesktop: true,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/office/ListDashesDark.svg',
      onClick: () => protectedRedirect(router, '/my-gear'),
      title: 'Gear',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/UserCircle.svg',
      onClick: () => protectedRedirect(null, `/users/${user?.username}`),
      title: 'My Profile',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/weather/StarDark.svg',
      onClick: () => protectedRedirect(router, '/my-favorites'),
      title: 'Favorites',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/commerce/CurrencyCircleDollarDark.svg',
      onClick: () => protectedRedirect(router, '/transactions'),
      title: 'Balance',
    },
    // {
    //   alt: 'icon',
    //   iconSrc: '/icons/media/HeadsetDark.svg',
    //   onClick: () => goTo(router, '#'),
    //   title: 'Support',
    //   divider: true,
    // },
  ];

  const guestMenuItems = [
    {
      alt: 'icon',
      iconSrc: '/icons/office/NotebookDark.svg',
      onClick: () => goTo(router, '/user-guide'),
      title: 'User guide',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/security/QuestionDark.svg',
      onClick: () => goTo(router, '/faq'),
      title: 'FAQ',
      divider: true,
    },
    {
      alt: 'icon',
      iconSrc: '/icons/commerce/StorefrontDark.svg',
      onClick: () => goTo(null, '/retail-partner'),
      title: 'Retail Partners',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/people/HandshakeDark.svg',
      onClick: () => goTo(null, 'https://www.sosendy.com/affiliate', true),
      title: 'Affiliate Program',
    },
    // {
    //   alt: 'icon',
    //   iconSrc: '/icons/people/UsersDark.svg',
    //   onClick: () => goTo(null, '/ambassadors'),
    //   title: 'SENDY Ambassadors',
    // },
    {
      alt: 'icon',
      iconSrc: '/icons/office/BookOpenDark.svg',
      onClick: () => goTo(null, 'https://www.sosendy.com/about', true),
      title: 'Story',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/communications/EnvelopeSimpleDark.svg',
      onClick: () => goTo(null, 'https://www.sosendy.com/contact', true),
      title: 'Contact',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/games/MedalDark.svg',
      onClick: () => goTo(null, 'https://www.sosendy.com/media', true),
      title: 'Media/PR',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/SnowboardNew.svg',
      onClick: () => goTo(null, 'https://www.sosendy.com/faq-snowboard', true),
      title: 'Q&A Snowboard',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/SkiNew.svg',
      onClick: () => goTo(null, 'https://www.sosendy.com/faq-skis', true),
      title: 'Q&A Skis',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/media/NewspaperDark.svg',
      onClick: () => goTo(router, '/blog'),
      title: 'SENDYpedia',
    },
    {
      alt: 'icon',
      iconSrc: '/icons/trident.svg',
      onClick: () => goTo(null, 'https://sendy.io/blog/sendy-stands-with-ukraine', true),
      title: 'Stand with Ukraine',
      divider: isLoggedIn,
    },
  ];

  const getMenuItems = () => {
    if (!isLoggedIn) {
      return guestMenuItems;
    }
    if (isAdmin) {
      return adminMenuItems;
    }
    return [...userMenuItems, ...guestMenuItems, ...adminMenuItems];
  };

  return (
    <div className={styles.container} onClick={toggleActive}>
      {isEmpty(user) ? (
        <NextIcon customClass={styles.dropdownIcon} src="/icons/office/ListDark.svg" size={32} alt="close" />
      ) : (
        <>
          <AccountAvatar
            sex={user?.sex || ''}
            photo={user?.photo}
            active={active}
            isAdmin={isAdmin}
          />
          <NextIcon
            customClass={styles.dropdownIcon}
            src={`/icons/arrows/Caret${(active && 'UpColor') || 'DownDark'}.svg`}
            size={16}
            alt="close"
          />
        </>
      )}
      <MenuModal active={active} items={getMenuItems()} />
    </div>
  );
};
